import Vue from "vue";
import Vuex from "vuex";
import * as fb from "../firebase";
import router from "../router/index";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    userProfile: {},
    boards: [],
    db: fb,
    unsubscribeBoards: null,
    undoList: [],
    cardTemplates: []
  },
  mutations: {
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setPerformingRequest(state, val) {
      state.performingRequest = val
    },
    setBoards(state, val) {
      state.boards = val
    },
    setCardTemplates(state, val) {
      state.cardTemplates = val
    },
    clearUndo(state) {
      state.undoList = []
    },
    addUndo(state, action) {
      state.undoList.unshift(action)
    },
    doUndo(state) {
      if (state.undoList.length < 1) return
      let item = state.undoList.shift()
      if (item.action === 'update') {
        fb.db.doc(item.entity).update(item.values)
      }
      if (item.action === 'create') {
        fb.db.doc(item.entity).set(item.values)
      }
      if (item.action === 'delete') {
        fb.db.doc(item.entity).delete()
      }
      if (item.action === 'unsell') { // Only for cards
        fb.db.doc(item.entity).set(item.values)
        fb.containersCollection.doc(item.values.container_id).update({
          gp: fb.fv.increment(-item.values.worth * (item.values.multiple || 1))
        })
      }

    }
  },
  actions: {

    async login({ dispatch }, user) {
      dispatch("fetchUserProfile", user)
    },

    async signup({ dispatch }, userData) {
      await fb.usersCollection.doc(userData.user.uid).set({
        name: userData.name
      });
      dispatch("fetchUserProfile", userData.user)
    },

    async fetchUserProfile({ commit }, user) {
      const userProfile = await fb.usersCollection.doc(user.uid).get()
      commit("setUserProfile", userProfile.data())

      // realtime firebase
      if (this.unsubscribeBoards != null) this.unsubscribeBoards
      this.unsubscribeBoards = fb.boardsCollection
                      .where('participants','array-contains', user.uid)
                      .where('archived', '==', false)
                      .orderBy("name", "asc")
                      .onSnapshot(snapshot => {
        let boardsArray = [];
        snapshot.forEach(doc => {
          let board = doc.data();
          board.id = doc.id;
          boardsArray.push(board);
        });
        store.commit("setBoards", boardsArray);

      });

      fb.cardTemplatesCollection.orderBy("name", 'asc').onSnapshot(snapshot => {
        let cardTemplateArray = [];
        snapshot.forEach(doc => {
          let cardTemplate = doc.data();
          cardTemplate.id = doc.id;
          cardTemplateArray.push(cardTemplate);
        });
        store.commit("setCardTemplates", cardTemplateArray);
      })

      if (router.currentRoute.path === "/login") { router.push("/"); }
    },

    async logout({ commit }) {
      if (this.unsubscribeBoards != null) this.unsubscribeBoards
      await fb.auth.signOut()
      commit("setUserProfile", {})
      router.push("/login")
    },

    async deleteAccount({commit}) {
      let user = fb.auth.currentUser;

      let userProvidedPassword = prompt('To permanently delete your account, please enter your current password.')
      let credential = fb.emailProvider.credential(
        user.email,
        userProvidedPassword
      );
      let self = this
      user.reauthenticateWithCredential(credential).then(function() {

        if (self.boards) self.boards.forEach((board) => {
          fb.boardsCollection.doc(this.board.id).update({
            admins: board.data().admins.filter(item => item != user.uid),
            participants: board.data().participants.filter(item => item != user.uid)
          })
        });

        if (self.unsubscribeBoards != null) self.unsubscribeBoards
        fb.auth.currentUser.delete().then( function() {
          commit("setUserProfile", {})
          fb.auth.signOut()
          router.push("/login")
        })

      }).catch(function(error) {
        alert(error)
      });

    },

    async updateProfile({ dispatch }, user) {
      const userId = fb.auth.currentUser.uid;
      await fb.usersCollection.doc(userId).update({ name: user.name })
      dispatch("fetchUserProfile", { uid: userId })
    }

  }
});

export default store;
