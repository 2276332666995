<template>
  <v-app-bar app dark>

    <v-img class="mr-2" max-height="40" max-width="40" contain src="@/assets/RIO.png" @click="go('/')" style="cursor:pointer"></v-img>
    <v-toolbar-title @click="go('/')" style="cursor:pointer" class="d-none d-sm-flex">
      RPG Inventory Organizer
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <JoinBoardComponent class="mr-4 d-none d-md-flex"></JoinBoardComponent>
    <NewBoardComponent class="mr-4 d-none d-md-flex"></NewBoardComponent>


    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="mr-4 d-none d-md-flex" dark color="secondary" v-bind="attrs" v-on="on">
          Games
        </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="(board, index) in boards" :key="index" @click="go(board_url(board))">
          <v-list-item-title>{{ board.name }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-btn @click="go('/Settings')" text>
      <span class="mr-2 d-none d-sm-flex">Settings</span>
      <v-icon>mdi-cog</v-icon>
    </v-btn>

    <v-btn @click="logout()" text>
      <span class="mr-2 d-none d-sm-flex">Logout</span>
      <v-icon>mdi-logout-variant</v-icon>
    </v-btn>

  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
import NewBoardComponent from '@/components/NewBoardComponent'
import JoinBoardComponent from '@/components/JoinBoardComponent'

export default {
  components: {
    NewBoardComponent,
    JoinBoardComponent
  },
  computed: {
    ...mapState(["boards"])
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    go (path) {
      this.$router.push(path).catch(()=>{});
    },
    board_url(board) {
      return '/Boards/'+board.id+'/'+board.name.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase().replace(/\s+/g, '-')
    },
    selectBoardId(id) {
      this.$store.commit("setSelectedBoard", id);
    }
  }
};
</script>
