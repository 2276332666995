<template lang="html">
  <div id="newBoardModal" class="">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="secondary" dark v-bind="attrs" v-on="on">
          Join Game
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey darken-3">
          Join a game
        </v-card-title>

        <v-card-text class="mt-5">
          To join a game in progress, your game admin can give you a code to enter here
        </v-card-text>
        <v-card-text>
          <v-form @submit.prevent>
            <v-text-field
            v-model.trim="join_id"
            :rules="[ v => !!v || 'Game code is required' ]"
            label="Game code"
            required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="joinBoard(); dialog = false">
            Join Game
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      join_id: ''
    };
  },
  computed: {
    ...mapState(["db"])
  },
  methods: {
    async joinBoard() {
      let self = this

      let mod = this.db.fv.arrayUnion(this.db.auth.currentUser.uid)
      this.db.boardsCollection.doc(this.join_id).update({
        participants: mod
      })
      .then(function() {
        self.$router.push('/boards/'+self.join_id).catch(()=>{})
      })
      .catch(function(error) {
        console.log(error)
      })

    }
  }
}
</script>

<style lang="css" scoped>
</style>
