<template lang="html">
  <div id="newBoardModal" class="">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="secondary" dark v-bind="attrs" v-on="on">
          New Game
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="headline grey darken-3">
          Create a game
        </v-card-title>

        <v-card-text>
          <v-form @submit.prevent>
            <v-text-field
            v-model.trim="board.name"
            :rules="[ v => !!v || 'Game name is required' ]"
            label="Game name"
            required
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="createBoard()">
            Create Game
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      board: {
        name: '',
        archived: false
      }
    };
  },
  computed: {
    ...mapState(["userProfile", "db"])
  },
  methods: {
    newBoard() {
      this.board = {
        name: '',
        archived: false
      }
    },
    async createBoard() {
      if (this.board.name.length < 1) return
      let self = this
      this.board.participants = [this.db.auth.currentUser.uid]
      this.board.admins = [this.db.auth.currentUser.uid]
      this.db.boardsCollection.add(this.board)
      .then(function(docRef) {
        self.$router.push('/boards/'+docRef.id).catch(()=>{});
        self.newBoard()
        self.dialog = false
      })

    }
  }
}
</script>

<style lang="css" scoped>
</style>
