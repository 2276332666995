import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { auth } from "./firebase";
import vuetify from "./plugins/vuetify";

const { create, all } = require('mathjs')
const config = {
  number: "BigNumber",
  precision: 20
}
const math = create(all,config)
Vue.prototype.$math = math;


Vue.config.productionTip = false;

let app;
auth.onAuthStateChanged(user => {
  if (!app) {
    app = new Vue({
      router,
      store,
      vuetify,
      render: h => h(App)
    }).$mount("#app");
  }

  if (user) {
    store.dispatch("fetchUserProfile", user);
  }
});
