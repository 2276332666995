<template>
  <v-app class="fill-height">
    <SiteNav v-if="showNav"></SiteNav>
    <!-- Sizes your content based upon application components -->
    <v-main class="fill-height">
      <!-- If using vue-router -->
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import SiteNav from '@/components/SiteNav'

export default {
  name: "App",
  components: {
    SiteNav
  },
  data: () => ({
    //
  }),
  computed: {
    ...mapState(['userProfile']),
    showNav() {
      return Object.keys(this.userProfile).length > 0
    }
  },
  methods: {
    keyHandler(event) {
      if (event.code == 'KeyZ' && (event.ctrlKey || event.metaKey)) {
        if (['INPUT','TEXTAREA'].includes(document.activeElement.nodeName)) return
        this.$store.commit('doUndo')
      }
    }
  },
  created(){
    document.title = "Inventory Organizer"
    window.addEventListener('keyup', this.keyHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.keyHandler);
  }
};
</script>

<style type="text/css">

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.v-application--wrap {
  min-height: 100% !important;
}
html, body {
  margin: 0;
  height: 100%;
  overflow: hidden !important;
}
</style>
