import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

var firebaseConfig = {
  apiKey: "AIzaSyB7zptvsVQCOjzMd2vkFFA1PcOXAd0rE34",
  authDomain: "rpg-items-containers.firebaseapp.com",
  projectId: "rpg-items-containers",
  storageBucket: "rpg-items-containers.appspot.com",
  messagingSenderId: "1070571695370",
  appId: "1:1070571695370:web:180984f9fda1572f581ef3"
};

firebase.initializeApp(firebaseConfig);

// utils
const db = firebase.firestore();
const fv = firebase.firestore.FieldValue
const auth = firebase.auth();
const storage = firebase.storage();
const emailProvider = firebase.auth.EmailAuthProvider;

// collection references
const usersCollection = db.collection("users");
const boardsCollection = db.collection("boards");
const containersCollection = db.collection("containers");
const cardsCollection = db.collection("cards");
const cardTemplatesCollection = db.collection("cardTemplates");


// export utils/refs
export {
  db,
  auth,
  storage,
  emailProvider,
  usersCollection,
  boardsCollection,
  containersCollection,
  cardsCollection,
  cardTemplatesCollection,
  fv
};
