<template lang="html">
  <v-container id="dashboard">
    <h1>Welcome</h1>
    <v-list>
      <v-list>
        <v-subheader><h2>Your current games</h2></v-subheader>
        <v-list-item v-for="(board, index) in boards" :key="index" @click="go(board_url(board))">
          <v-list-item-content>
            <v-list-item-title>{{ board.name }}</v-list-item-title>
            <v-list-item-subtitle>
              <span>{{board.participants.length}} players</span>
              <span v-if="isAdmin(board)">, administrator</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <NewBoardComponent class="mr-4"></NewBoardComponent>
          <JoinBoardComponent class="mr-4"></JoinBoardComponent>
        </v-list-item>
      </v-list>
    </v-list>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import NewBoardComponent from '@/components/NewBoardComponent'
import JoinBoardComponent from '@/components/JoinBoardComponent'

export default {
  components: {
    NewBoardComponent,
    JoinBoardComponent
  },
  computed: {
    ...mapState(["db","boards"])
  },
  methods: {
    go (path) {
      this.$router.push(path).catch(()=>{});
    },
    board_url(board) {
      return '/Boards/'+board.id+'/'+board.name.replace(/[^a-zA-Z0-9 ]/g, "").toLowerCase().replace(/\s+/g, '-')
    },
    isAdmin(board) {
      return board.admins && board.admins.includes(this.db.auth.currentUser.uid)
    }
  }
};
</script>

<style lang="css" scoped>
</style>
